import { debounce } from 'lodash'
export default {
  methods: {
    setItemsPerPageOptions ({ table, value }) {
      this.$store.commit('pagination/setItemsPerPageOptions', { itemsPerPageOptions: value || [ 25, 50 ], table })
    },
    triggerSearch () {
      this.$store.dispatch('pagination/filterListRequest', { table: this.table })
    },
    updatePagination (response) {
      this.$store.commit('pagination/update', { length: response.list.length, hasNext: response.meta.hasNext, table: this.table })
      this.$store.commit('pagination/setLoading', { table: this.table, loading: false })
      var table = document.getElementsByClassName('v-table__overflow')[0]
      if (table) {
        table.scrollTop = 0
      }
    }
  },
  computed: {
    tablePagination () {
      return this.pagination.tables[this.table]
    }
  },
  created () {
    this.debounceSearch = debounce(this.triggerSearch, 800)
    this.debounceList = debounce(this.triggerSearch, 150)
    this.$store.commit('pagination/initializeTable', { table: this.table, listMethod: this.list })
  },
  watch: {
    search (value) {
      if (this.storeSearch) {
        sessionStorage.setItem(`filterConfig:${this.table}:Search`, JSON.stringify(value))
      }
      if (!value) {
        this.$store.dispatch('pagination/filterListRequest', { table: this.table })
      } else {
        this.debounceSearch()
      }
    }
  }
}
